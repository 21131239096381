body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDrawer-root .MuiListItem-root:hover * {
  color: #6782b4;
}

.MuiDrawer-root .MuiListItem-root.active:hover *,
.MuiDrawer-root .MuiListItem-root.active * {
  color: #3d4977;
}

.MuiDrawer-root .MuiListItem-root .MuiTypography-body1  {
  font-size: .9em;
}

.MuiDrawer-root .MuiListItem-root.active .MuiTypography-body1 {
  font-weight: bold;
}

.MuiOutlinedInput-root:hover *[readonly] + .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.10);
}

.EditableComponent-root:hover *[readonly] + .MuiOutlinedInput-notchedOutline {
  border-color: black;
}

html, body, div#root, div#root > div, div#root > div > div {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'archimate-elements';
  src: url('./css/fonts/archimate-elements.eot?wva01a');
  src: url('./css/fonts/archimate-elements.eot?wva01a#iefix') format('embedded-opentype'), url('./css/fonts/archimate-elements.ttf?wva01a') format('truetype'), url('./css/fonts/archimate-elements.woff?wva01a') format('woff'), url('./css/fonts/archimate-elements.svg?wva01a#archimate-elements') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="archimate-icon"], [class*=" archimate-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'archimate-elements' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span.archimate-icon {
  filter: saturate(3.0) brightness(0.8);
}

.MuiAutocomplete-listbox span.MuiCheckbox-root {
  padding-top: 5px;
  padding-bottom: 6px;
}

.svgNodeMenuShadow {
  filter: drop-shadow(0 0 0.2rem lightgray);
}

.svgPaperShadow {
  filter: drop-shadow(0 0 0.15rem lightgray);
}
